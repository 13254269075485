import React from "react"
import window from 'global'

const App = () => {
  if(window.navigator){
      let to =  'https://syndic-en-un-clic.fr'
      if(window.navigator.userAgent.toLowerCase().indexOf("android") > -1){
        to = 'https://play.google.com/store/apps/details?id=com.syndicenunclic.syndic'
      }
      if(window.navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
        to = 'https://apps.apple.com/fr/app/syndic/id1498192384'
      }
      window.location.href = to
  }
  return (
    <h1>...</h1>
  )
  }

export default App
